import { tween } from 'shifty';

function GlobalHeader() {
   this.headerDropdownCallbacks = {};

   this.toggleHeaderDropdown = function (dropdownContainer, forceShow) {
      let dropdown = dropdownContainer.getElement('.header-dropdown');
      let isOpen = dropdownContainer && dropdownContainer.get('data-dropdown-header-open') === '';

      if (forceShow !== undefined && forceShow === isOpen) {
         return;
      }

      if (isOpen) {
         dropdownContainer.set('data-dropdown-header-open', null);
         tween({
            from: { y: 0 },
            to: { y: -100 },
            duration: 400,
            render: ({ y }) => {
               dropdown.style.transform = `translateY(${y}%)`;
            },
         }).then(() => {
            dropdownContainer.removeClass('dropdown-container-active');
         });
      } else {
         this.closeOpenHeaderDropdowns();
         dropdownContainer.set('data-dropdown-header-open', '');
         dropdownContainer.addClass('dropdown-container-active');
         tween({
            from: { y: -100 },
            to: { y: 0 },
            duration: 200,
            render: ({ y }) => {
               dropdown.style.transform = `translateY(${y}%)`;
            },
         });
      }

      let callback = this.headerDropdownCallbacks[dropdownContainer.get('id')];
      if (callback) {
         callback(!isOpen);
      }
   };

   this.setupHeaderDropdown = function (toggleButton, dropdown, options) {
      if (!options) {
         options = {};
      }

      // Toggle the dropdown if the toggle button is clicked. Stop the process
      // if an 'unlock' boolean function is passed in and returns false.
      toggleButton.addEvent('click', ev => {
         if (!(options.unlock && !options.unlock())) {
            options.onFirstOpen && options.onFirstOpen();
            delete options.onFirstOpen;
            ev.preventDefault();
            ev.stopPropagation();
            this.toggleHeaderDropdown(dropdown);
         }
      });

      // Close the dropdown when clicking outside of it.
      window.addEvent('click', ev => {
         if (!(dropdown.contains(ev.target) || toggleButton.contains(ev.target))) {
            this.toggleHeaderDropdown(dropdown, false);
         }
      });
   };

   this.closeOpenHeaderDropdowns = function () {
      $$('[data-dropdown-header-open]').each(dropdown => {
         this.toggleHeaderDropdown(dropdown, false);
      });
   };

   this.showLoggedIn = function (info) {
      when($('loggedInNav'), loggedInNav => {
         let firstName = info.username.replace(/\s+.*$/, '');
         $('menuUsername').set('text', firstName);
         $('loggedOutNav').hide();
         loggedInNav.show();
      });
   };

   this.showLoggedOut = function () {
      when($('loggedInNav'), loggedInNav => {
         loggedInNav.hide();
         $('loggedOutNav').show();
      });
   };
}

export { GlobalHeader };
